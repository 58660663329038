@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap);

div.recovery{
    height: 100vh;
    margin: 0;
}
form.recovery{
    height: 100%;
    margin: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    max-width: 500px;
    grid-gap: 20px;
    gap: 20px;
    padding: 10px;
    
}
button.recovery{
    width: 50%;
    padding: 5px;
}
input.recovery{
    width: 100%;
    padding: 5px;
}
.recovery_popup{
    display:-webkit-flex;
    display:flex;
    -webkit-align-items: center;
            align-items: center;
    max-width: 500px;
    grid-gap: 20px;
    gap: 20px;
    width: 100%;
}
.recovery_popup_button{
    max-width: 100px;
    margin-left: auto;
}

.hideDiv{
    display: none !important;
}

div.recovery{
    height: 100vh;
    margin: 0;
}
form.recovery{
    height: 100%;
    margin: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    max-width: 500px;
    grid-gap: 20px;
    gap: 20px;
    padding: 10px;
    
}
button.recovery{
    width: 50%;
    padding: 5px;
}
input.recovery{
    width: 100%;
    padding: 5px;
}
.recovery_popup{
    display:-webkit-flex;
    display:flex;
    -webkit-align-items: center;
            align-items: center;
    max-width: 500px;
    grid-gap: 20px;
    gap: 20px;
    width: 100%;
}
.recovery_popup_button{
    max-width: 100px;
    margin-left: auto;
}

.hideDiv{
    display: none !important;
}
.container__default{
    height: 100vh;
    margin: 0;
    overflow: hidden;
}
.recovery p{
    color: white;
}

div.recovery{
    height: 100vh;
    margin: 0;
}
form.recovery{
    height: 100%;
    margin: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    max-width: 500px;
    grid-gap: 20px;
    gap: 20px;
    padding: 10px;
    
}
button.recovery{
    width: 50%;
    padding: 5px;
}
input.recovery{
    width: 100%;
    padding: 5px;
}
.recovery_popup{
    display:-webkit-flex;
    display:flex;
    -webkit-align-items: center;
            align-items: center;
    max-width: 500px;
    grid-gap: 20px;
    gap: 20px;
    width: 100%;
}
.recovery_popup_button{
    max-width: 100px;
    margin-left: auto;
}

.hideDiv{
    display: none !important;
}
.container__default{
    height: 100vh;
    margin: 0;
    overflow: hidden;
}
.recovery p{
    color: white;
}
.SbotFrom .input__defalt p {
  line-height: 2em;
  color: #fff;
}
.SbotFrom a {
  font-weight: bold;
}
.SbotFrom a:hover {
  color: #fff;
  opacity: 0.5;
  font-weight: bold;
}

.final__message{
    height: 100%;
    margin: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    max-width: 500px;
    grid-gap: 20px;
    gap: 20px;
    padding: 10px;
    line-height: 1.5em;
    color: white;
}
.final__message a{
    font-weight: normal;
    text-decoration: underline;
    color: white;

}
.final__message a:hover{
    color: white;
    
}

div.input__defalt{
    height: 100vh;
    margin: 0;
}
form.input__defalt{
    height: 100%;
    margin: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    max-width: 500px;
    grid-gap: 20px;
    gap: 20px;
    padding: 10px;
}
button.input__defalt{
    width: 30%;
    padding: 5px;
}
input.input__defalt{
    width: 100%;
    padding: 5px;
}
.input__defalt_popup{
    display:-webkit-flex;
    display:flex;
    -webkit-align-items: center;
            align-items: center;
    max-width: 500px;
    grid-gap: 20px;
    gap: 20px;
    width: 100%;
}
.input__defalt_popup_button{
    max-width: 100px;
    margin-left: auto;
}

.hideDiv{
    display: none !important;
}
.final__message{
    height: 100%;
    margin: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    max-width: 500px;
    grid-gap: 20px;
    gap: 20px;
    padding: 10px;
    line-height: 1.5em;
    color: white;
}
.link{
    font-weight: normal;
    text-decoration: underline;
    color: white;

}
.link:hover{
    color: white;
    
}

div.input__defalt{
    height: 100vh;
    margin: 0;
}
form.input__defalt{
    height: 100%;
    margin: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    max-width: 500px;
    grid-gap: 20px;
    gap: 20px;
    padding: 10px;
}
button.input__defalt{
    width: 30%;
    padding: 5px;
}
input.input__defalt{
    width: 100%;
    padding: 5px;
}
.input__defalt_popup{
    display:-webkit-flex;
    display:flex;
    -webkit-align-items: center;
            align-items: center;
    max-width: 500px;
    grid-gap: 20px;
    gap: 20px;
    width: 100%;
}
.input__defalt_popup_button{
    max-width: 100px;
    margin-left: auto;
}

.hideDiv{
    display: none !important;
}
.container__default{
    height: 100vh;
    margin: 0;
    overflow: hidden;
}
.btnContainer{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.container__default p{
    color: white;
}
.container__default-sbdpe{
    margin: auto;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    max-width: 500px;
height: 100vh;
}
.container__default-sbdpe p{
    margin-bottom: 15px;
}
.container__default-sbdpe{
    margin: auto;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    max-width: 500px;
height: 100vh;
}
.container__default-sbdpe p{
    margin-bottom: 15px;
}
/* Document
 * ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}
/* Sections
 * ========================================================================== */
/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}
/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}
/**
 * Add the correct display in IE.
 */
main {
  display: block;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/* Text-level semantics
 * ========================================================================== */
/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}
/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}
/* Embedded content
 * ========================================================================== */
/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}
/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
button,
input,
select {
  margin: 0;
}
/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */
button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}
/**
 * Correct the inability to style buttons in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
/**
 * Show the overflow in Edge 18- and IE.
 */
input {
  overflow: visible;
}
/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}
/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}
/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none;
}
/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */
textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
}
/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/**
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}
/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge 18- and IE.
 */
details {
  display: block;
}
/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
dialog:not([open]) {
  display: none;
}
/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}
/* Scripting
 * ========================================================================== */
/**
 * Add the correct display in IE.
 */
template {
  display: none;
}
/* User interaction
 * ========================================================================== */

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}
html{
  line-height: 2.15;
}
.radioAdjust {
  margin-right: 3px;
}
.gender__input {
  margin-top: 5px;
}
.desabled {
  display: none;
}
body {
  /*background-image: "c";*/  
  font-family: 'Montserrat', sans-serif;
  min-width: 305px;
}
a {
  text-decoration: none;
  color: inherit;
  transition: 0.5s;
}
a:hover {
  color: #fe7917;
}
label {
  display: block;
}
.button__default {
  font-weight: bold;
  padding: 7px;
  border-radius: 5px;
  background-color: #ccccd6;
  transition: 0.5s;
  border: none;
  color: #777;
  cursor: pointer;
  float: right;
  margin-left: 10px;
  transition: background-color 0s;
  transition: color 0.5s;
  box-shadow: 0px 0px 20px #fff inset;
}

.button__default:hover {
  background-color: #fe7917;
  color: white;
  box-shadow: 0px -20px 20px rgba(0, 0, 0, 0.075) inset;
}

.button__default--action {
  background-color: #fe7917;
  color: white;
  box-shadow: 0px -20px 20px rgba(0, 0, 0, 0.075) inset;
  font-weight: bold;
  padding: 7px;
  border-radius: 5px;
  transition: 0.5s;
  border: none;
  cursor: pointer;
  float: right;
  margin-left: 0 !important;
  transition: background-color 0s;
  transition: color 0.5s;
  margin-bottom: 0 important!;
}

.button__default:active {
  background-color: #fe7917;
  color: white;
  box-shadow: 0px 0px 2px 1px #fff;
}

.button__default--action:active {
  background-color: #fe7917;
  color: white;
  box-shadow: 0px 0px 2px 1px #fff;
}

.input__default {
  padding: 5px 10px;
  border-radius: 2px;
  border: none;
  width: 100%;
  /* box-shadow: 0px 0px 2px #000 inset; */
  /* margin-bottom: 10px; */
}
.input__default:disabled {
  background-color: white;
  opacity: 0.5;
}
.input__default--twoDig:disabled {
  background-color: white;
  opacity: 0.5;
}

.form__conteinerFlex {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}
iframe {
  border: none;
}
.successtext_register {
  text-align: center;
  padding: 0px 85px;
  line-height: 24px;
}

.checks {
  display: -webkit-flex;
  display: flex;
  /* gap: 1rem; */
  margin: 1.5rem 0;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: baseline;
          align-items: baseline;
}
/* .checks p {
  line-height: 1.5rem;
} */
.newsletter,
.policy {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-align: center;
  font-size: 1.02rem;
}
.policy a {
  font-weight: bold;
  text-decoration: underline;
}
#newsletter,
#policy {
  width: 15px;
  margin-right: .4rem;
  border-radius: 2px;
  border-bottom: 1px solid #727377 
}
.btn_isLinked {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

 @media only screen and (max-width: 465px) {
   .policy {
     -webkit-align-items: baseline;
             align-items: baseline;
     -webkit-box-align: center;
    }
    .newsletter, .policy {
      -webkit-align-items: baseline;
              align-items: baseline;
    }      
  }

.btn-login-plataforma {
  float: unset !important;
  margin-top: 20px;
}

#background-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

